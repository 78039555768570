@import '../../style/theme.scss';
@import '../../style/constants.scss';
@import '../../style/mixins.scss';
@import '../../../node_modules/sass-mq/mq';

.modal-container {
  background-color: #fff;
  &.stand-alone-video {
    background-color: inherit;
  }
  border-radius: 10px;
  position: relative;
  max-width: $desktop-max-width;

  // @include ie10-11 {
  @include mq($until: desktop) {
    position: absolute;
    top: 2em;
    left: 0;
    right: 0;
  }
  // }
  .close {
    cursor: pointer;
    position: absolute;
    top: -2em;
    right: -2em;
    width: 4em;
    height: 4em;
    background-size: contain;
    background-repeat: no-repeat;
    text-align: center;
    z-index: 50000;
    @include mq($until: desktop) {
      top: -$spacing;
      right: $spacing / 4;
    }
  }

  .modal-close {
    cursor: pointer;
    position: absolute;
    // top: -$spacing * 2;
    top: 0;
    right: -$spacing * 3;
    transition: all 0.2s;

    @include mq($until: desktop) {
      top: $spacing / 4;
      right: $spacing / 4;
    }
  }

  .modal-content {
    background-color: #fff;
    border-radius: 10px;
    height: 100%;
    width: $desktop-max-width;

    @include mq($until: desktop) {
      width: 100vw;
    }
  }
  header.modal-title {
    width: 100%;
    margin-top: 1em;
    h5 {
      text-transform: uppercase;
      margin: 0;
      font: {
        family: 'MontserratBold';
        size: 1.3em;
      }
      @include mq($until: desktop) {
        font: {
          size: 1em;
        }
        margin-top: 2.5em;
        max-width: 95%;
      }
    }
  }
}
