@import '../../style/theme.scss';
@import '../../../node_modules/sass-mq/mq';

.modal {
  align-items: center;
  background: rgba(white, 0);
  display: flex;
  font: {
    family: 'MontserratRegular';
  }
  justify-content: center;
  left: 45%;
  max-height: 100%;
  outline: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;

  @include mq($until: desktop) {
    align-items: initial;
    bottom: 0;
    left: 0;
    max-height: none;
    overflow: scroll;
    right: 0;
    top: 0;
    transform: none;
  }
}

.overlay {
  background-color: rgba($grey2, 0.8);
  bottom: 0px;
  left: 0px;
  right: 0px;
  top: 0px;
  position: fixed;
  z-index: 500000;

  // @include mq($until: desktop) {
  //   position: absolute;
  // }
}
