@import '../../style/mixins.scss';
@import '../../style/theme.scss';
@import '../../../node_modules/sass-mq/mq';

.footer {
  background-color: map-get($map: $footer, $key: background);
  color: map-get($map: $footer, $key: color);
  flex-shrink: 0;
  font: {
    family: 'MontserratBold';
  }
  height: 86px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @include mq($until: desktop) {
    display: block;
    padding-top: 0.5em;
  }

  @media (max-width: 1500px) {
    height: 70px;
  }
  ul {
    height: 100%;
    display: flex;
    align-items: center;
    &.footer-menu {
      justify-content: flex-start;
      @include mq($until: desktop) {
        display: block;
        min-height: 6em;
      }
      li {
        cursor: pointer;
        margin: 0 5px;
        border-right: $primary solid 2px;
        padding: 0 10px;
        &:last-of-type {
          border-right: inherit;
        }
        &:hover {
          cursor: pointer;
        }
        @include mq($until: desktop) {
          border-right: inherit;
          text-align: center;
          //padding-top: 1em;
          padding-bottom: 1em;
        }
      }
    }
    &.footer-logos {
      justify-content: flex-end;
      padding-right: 1em;
      @include mq($until: desktop) {
        //display: block;
        text-align: center;
      }
      li{
        img{
          max-width: 75%;
        }
      }
    }
    li {
      margin-left: 1em;
    }
    @include mq($until: desktop) {
      // margin-top: 172px;
    }
  }

}

.page_link {
  width: 920px;
  height: 518px;
  align-items: center;

  font: {
    family: 'MontserratRegular';
  }
  justify-content: center;
  left: 50%;
  max-height: 100%;
  outline: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  @include mq($until: desktop) {
    width:100%;
    margin-top:5em;
  }
  .footer-modal-content:not(.modal-close) {
    color: #0b3c59;
    background-color: white;
    font-size:1.5em;
    padding:1em;
    ul{
      list-style: disc;
      font-size:0.8em;
      padding-left:2em;
      margin-bottom:0.5em;
    }
    p{
      margin-bottom:0.5em;
    }
  }
  @include mq($until: desktop) {
    align-items: initial;
    bottom: 0;
    left: 0;
    max-height: none;
    //overflow: scroll;
    right: 0;
    top: 0;
    transform: none;
  }
  .modal-close {
    cursor: pointer;
    position: absolute;
    // top: -$spacing * 2;
    top: 0;
    right: -$spacing * 3;
    transition: all 0.2s;
    z-index: 200000;
    @include mq($until: desktop) {
       top: -4em;
      right: $spacing / 4;
    }
  }
  .footer-modal {
    min-width: 920px;
    padding: 20px;
    height: 518px;
    background-color: white;
    border-radius:  25px;
    h5 {
      margin: 10px 0 20px 0;
      font-size: 3em;
      text-align: center;
      text-transform: uppercase;
    }
    p {
      font-size: 1.5em;
      width: 100%;
      font-weight: bold;
    }
    ul {
      font-size: 1.5em;
      list-style: disc;
      margin-left: 20px;
      padding: 15px;
      li {
        margin-bottom: 15px;
      }
    }
  }
}

.overlay {
  background-color: rgba($grey2, 0.8);
  bottom: 0px;
  left: 0px;
  right: 0px;
  top: 0px;
  position: fixed;
  z-index: 500;
}
