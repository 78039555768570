@import '../../../../style/theme.scss';
@import '../../../../style/constants.scss';
@import '../../../../../node_modules/sass-mq/mq';
@import '../../../../style/mixins.scss';

.download-link {
  width: 400px;
  margin-top: 0.5em;
  @include mq($until: desktop) {
    width: 100%;
  }
  a {
    font: {
      family: 'MontserratBold';
      size: 1.6em;
    }
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    padding-top: 8em;
    position: relative;
    &::before {
      content: '';
      width: 5em;
      height: 5em;
      position: absolute;
      top: 2em;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      //background-color: red;
      background-image: url('../../../../img/layout/icons/download_icon.png');
      background-size: cover;
    }
  }
}
