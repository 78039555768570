@import '../../node_modules/sass-mq/mq';

body,
html {
  height: 100%;
  padding: 0;
  margin: 0;
  font: {
    family: 'MontserratRegular', 'Open Sans';
    size: 1em;
    weight: regular;
  }
  color: $grey2;
}
#root {
  min-height: 100%;
}
#root,
.body {
  display: flex;
  flex-direction: column;
  height: 100% !important;
}

.body {
  position: relative;
}

p {
  margin: 0;
  font-size: 0.8em;
}

div {
  box-sizing: border-box;
}

h1 {
  font-size: 1.2em;
}

h2 {
  font-size: 1em;
  margin: 0;
}

h3 {
  font-size: 0.95em;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
  max-height: 100%;
}

form,
fieldset {
  border: none;
  outline: none;
  padding: 0;
  margin: 0;

  legend {
    margin: 0;
    padding: 0;
  }

  input,
  select {
    border: none;
    outline: none;
    background: none;
  }
}

button {
  border: none;
  background: none;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

figure {
  margin: 0;

  figcaption {
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
}

.bloc-left {
  float: left;
}

.bloc-right {
  float: right;
}

.clearfix {
  clear: both;
}

.table-element {
  display: table;
}

.table-cell-element {
  display: table-cell;
  vertical-align: middle;
}

.hidden {
  display: none !important;
}

.hidden-desktop {
  @include mq($from: desktop) {
    display: none !important;
  }
}

.hidden-mobile {
  @include mq($until: desktop) {
    display: none !important;
  }
}
