@import '../../../../style/theme.scss';
@import '../../../../../node_modules/sass-mq/mq';
@import '../../../../style/mixins.scss';

.static {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 940px;
  height: 490px;
  overflow: hidden;
  margin-top: 0.2em;

  @include mq($until: desktop) {
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  img {
    // @include ie10-11 {
    &.stand-alone {
      width: 100%;
    }
    // }
    @include mq($until: desktop) {
      &:not(.stand-alone) {
        max-height: 80vh;
        //max-width: 78vw;
      }

      &.stand-alone {
        max-width: none;
        width: 200vw;
      }
    }
  }

  .next-button {
    cursor: pointer;
    position: absolute;
    bottom: $spacing / 5;
    right: $spacing;

    @include mq($until: desktop) {
      right: 50%;
      transform: translateX(50%);

      // @include ie10-11 {
      &.stand-alone {
        position: static;
        transform: none;
        margin-top: $spacing / 2;
      }
      // }
    }
  }
}
