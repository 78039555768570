//@import '../../style/theme.scss';
.box {
  &.herit1 {
    background: $herit1;
    &:hover:not(.locked) {
      cursor: pointer;
      background: lighten($herit1, 10) !important;
    }
    .locked-filter {
      cursor: not-allowed;
      background-color: darken($herit1, 5);
    }
  }
  &.herit2 {
    background: $herit2;

    &:hover:not(.locked) {
      cursor: pointer;
      background: lighten($herit2, 10) !important;
    }
    &.locked {
      cursor: not-allowed;
      background: darken($herit2, 5);
    }
  }
  &.herit3 {
    background: $herit3;
    &:hover:not(.locked) {
      cursor: pointer;
      background: lighten($herit3, 10) !important;
    }
    &.locked {
      cursor: not-allowed;
      background: darken($herit3, 5);
    }
  }
  &.herit4 {
    background: $herit4;

    &:hover:not(.locked) {
      cursor: pointer;
      background: lighten($herit4, 10) !important;
    }
    &.locked {
      cursor: not-allowed;
      background: darken($herit4, 5);
    }
  }
  &.herit5 {
    background: $herit5;

    &:hover:not(.locked) {
      cursor: pointer;
      background: lighten($herit5, 20) !important;
    }
    &.locked {
      cursor: not-allowed;
      background: darken($herit5, 10);
    }
  }
}
.menu {
  color: $herit1;
  &.herit1 {
    .close {
      background-image: url('../../img/layout/buttons/herit1_close.png');
    }
    h4.title {
      span {
        border-bottom: 3px solid $herit1;
        padding-left: 2em;
      }
    }
    .box {
      cursor: pointer;
      background-color: $herit1;
      &.tail {
        color: #fff;
      }
      .locked-filter {
        cursor: not-allowed;
      }
      &[order='2'] {
        background-color: #079be6;
      }
      &[order='5'] {
        background-color: #0570a6;
      }
    }

    .slider-button.next.extranext {
      background-color: $herit1;
    }
  }

  &.herit2 {
    color: $herit2;
    .close {
      background-image: url('../../img/layout/buttons/herit2_close.png');
    }
    h4.title {
      span {
        border-bottom: 3px solid $herit2;
        padding-left: 2em;
      }
    }
    .box {
      cursor: pointer;
      background-color: $herit2;
      &.tail {
        color: #fff;
      }
      .locked-filter {
        cursor: not-allowed;
      }
      &[order='2'] {
        background-color: #8652c4;
      }
      &[order='4'] {
        background-color: #5a3885;
      }
      &[order='6'] {
        background-color: #5a3885;
      }
    }

    header {
      .close {
        color: $herit2;
      }
    }
    .slider-button.next.extranext {
      background-color: $herit2;
    }
  }

  &.herit3 {
    color: $herit3;
    .close {
      background-image: url('../../img/layout/buttons/herit3_close.png');
    }
    h4.title {
      span {
        border-bottom: 3px solid $herit3;
        padding-left: 2em;
      }
    }
    .box {
      cursor: pointer;
      background-color: $herit3;
      &.tail {
        color: #fff;
      }
      .locked-filter {
        cursor: not-allowed;
      }
      &[order='2'] {
        background-color: #37bf92;
      }
      &[order='4'] {
        background-color: #2b9b9b;
      }
      &[order='6'] {
        background-color: #2b9b9b;
      }
    }
    header {
      .close {
        color: $herit3;
      }
    }
    .slider-button.next.extranext {
      background-color: $herit3;
    }
  }

  &.herit4 {
    color: #3f235f;
    .close {
      background-image: url('../../img/layout/buttons/herit4_close.png');
    }
    h4.title {
      span {
        border-bottom: 3px solid #3f235f;
        padding-left: 0.5em;
        font-size: 0.85em;
        @include mq($until: desktop) {
          font: {
            size: 0.8em;
          }
        }
      }
    }
    .box {
      cursor: pointer;
      background-color: $herit4;
      &.tail {
        color: #fff;
      }
      .locked-filter {
        cursor: not-allowed;
      }
      &[order='2'] {
        background-color: #ea960e;
      }
      &[order='4'] {
        background-color: #ea960e;
      }
      &[order='6'] {
        background-color: #ea960e;
      }
    }
    header {
      .close {
        color: $herit4;
      }
    }
    .slider-button.next.extranext {
      background-color: $herit4;
    }
  }

  &.herit5 {
    color: $herit5;
    .close {
      background-image: url('../../img/layout/buttons/herit5_close.png');
    }
    h4.title {
      span {
        border-bottom: 3px solid $herit5;
        padding-left: 2em;
      }
    }
    .subtitle {
      color: #033b58;
    }
    .box {
      cursor: pointer;
      background-color: $herit5;
      &.tail {
        color: #fff;
      }
      .locked-filter {
        cursor: not-allowed;
      }
      &[order='1'] {
        background-color: #ff915b;
      }
      &[order='3'] {
        background-color: #ff915b;
      }
      &[order='5'] {
        background-color: #ff915b;
      }
    }
    header {
      .close {
        color: $herit5;
      }
    }
    .slider-button.next.extranext {
      background-color: $herit5;
    }
  }
}
.modal-container {
  //////herit1//////
  &.herit1 {
    color: $herit1;
    .close {
      background-image: url('../../img/layout/buttons/herit1_close.png');
    }
    header {
      &.modal-title {
        h5 {
          span {
            border-bottom: 3px solid $herit1;
            padding-left: 2em;
          }
        }
      }
    }
    .custom-background-color {
      background-clip: padding-box;
      background-color: lighten($herit1, 65);
      -webkit-box-shadow: inset 0px -2px 0px 18px rgba(255, 255, 255, 1);
      -moz-box-shadow: inset 0px -2px 0px 18px rgba(255, 255, 255, 1);
      box-shadow: inset 0px -2px 0px 18px rgba(255, 255, 255, 1);
    }
    .embedded-video-content {
      strong {
        color: $herit1;
      }
    }

    button.validate {
      color: #e95c0c;
    }
    .answer-group {
      button {
        border: solid 2px $herit1;
        color: $herit1;
        &:hover {
          //background: darken($herit1, 5);
        }
        &.selected {
          background-color: $herit1;
          color: white;
          box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.6);
          //border: solid 5px $herit1;
          font: {
            family: 'MontserratBold';
          }
        }
      }
    }
    .question-title {
      background-color: lighten($herit1, 65);
    }
    .quiz-question {
      p {
        color: #fff;
        background-color: $herit1;
      }
    }
    .embedded-content {
      .bool-title {
        color: $herit1;
        border-left: 0.1em solid $herit1;
      }
      .rich-text-content {
        &:first-child {
          border-right-color: $herit1;
        }
      }
    }
    .embedded-content-video {
      .bool-title {
        color: $herit1;
        border-left: 0.1em solid $herit1;
      }
    }
    .embedded-content,
    .embedded-content-video {
      .rich-text {
        .arrow::before {
          content: '';
          position: absolute;
          width: 3.25em;
          height: 2.2em;
          right: 0;
          bottom: -50%;
          background-image: url('../../img/layout/buttons/herit1_arrow.png');
          background-repeat: no-repeat;
          background-size: contain;
          @include mq($until: desktop) {
            transform: rotate(90deg);
          }
        }
      }
    }
    .resources-wrapper {
      .resource-link {
        color: $herit1;
        background-color: lighten($herit1, 65);
        &::before {
          background-image: url('../../img/layout/resources/herit1_resources_icon.png');
        }
      }
    }

    .return {
      background: $herit1;
    }
    .slider-button {
      &.next {
        background-image: url('../../img/layout/buttons/herit1_next.png');
      }
      &.previous {
        background-image: url('../../img/layout/buttons/herit1_prev.png');
      }
    }
  }
  //////herit2//////
  &.herit2 {
    color: $herit2;
    .close {
      background-image: url('../../img/layout/buttons/herit2_close.png');
    }
    header {
      &.modal-title {
        h5 {
          span {
            border-bottom: 3px solid $herit2;
            padding-left: 2em;
          }
        }
      }
    }
    .custom-background-color {
      background-clip: padding-box;
      background-color: lighten($herit2, 65);
      -webkit-box-shadow: inset 0px -2px 0px 18px rgba(255, 255, 255, 1);
      -moz-box-shadow: inset 0px -2px 0px 18px rgba(255, 255, 255, 1);
      box-shadow: inset 0px -2px 0px 18px rgba(255, 255, 255, 1);
    }
    .embedded-video-content {
      strong {
        color: $herit2;
      }
    }

    button.validate {
      color: #ea960d;
    }
    .answer-group {
      button {
        border: solid 2px $herit2;
        color: $herit2;
        &:hover {
          //background: darken($herit1, 5);
        }
        &.selected {
          background-color: $herit2;
          color: white;
          box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.6);
          //border: solid 5px $herit1;
          font: {
            family: 'MontserratBold';
          }
        }
      }
    }
    .question-title {
      background-color: lighten($herit2, 65);
    }
    .quiz-question {
      p {
        color: #fff;
        background-color: $herit2;
      }
    }
    .embedded-content {
      .bool-title {
        color: $herit2;
        border-left: 0.1em solid $herit2;
      }
      .rich-text-content {
        &:first-child {
          border-right-color: $herit2;
        }
      }
    }
    .embedded-content-video {
      .bool-title {
        color: $herit2;
        border-left: 0.1em solid $herit2;
      }
    }
    .embedded-content,
    .embedded-content-video {
      .rich-text {
        .arrow::before {
          content: '';
          position: absolute;
          width: 3.25em;
          height: 2.2em;
          right: -4.5em;
          background-image: url('../../img/layout/buttons/herit2_arrow.png');
          background-repeat: no-repeat;
          background-size: contain;
          @include mq($until: desktop) {
            transform: rotate(90deg);
          }
        }
      }
    }
    .resources-wrapper {
      .resource-link {
        color: $herit2;
        background-color: lighten($herit2, 65);
        &::before {
          background-image: url('../../img/layout/resources/herit2_resources_icon.png');
        }
      }
    }

    .return {
      background: $herit1;
    }
    .slider-button {
      &.next {
        background-image: url('../../img/layout/buttons/herit2_next.png');
      }
      &.previous {
        background-image: url('../../img/layout/buttons/herit2_prev.png');
      }
    }
  }
  //////herit3//////
  &.herit3 {
    color: $herit3;
    .close {
      background-image: url('../../img/layout/buttons/herit3_close.png');
    }
    header {
      &.modal-title {
        h5 {
          span {
            border-bottom: 3px solid $herit3;
            padding-left: 2em;
          }
        }
      }
    }
    .custom-background-color {
      background-clip: padding-box;
      background-color: lighten($herit3, 40);
      -webkit-box-shadow: inset 0px -2px 0px 18px rgba(255, 255, 255, 1);
      -moz-box-shadow: inset 0px -2px 0px 18px rgba(255, 255, 255, 1);
      box-shadow: inset 0px -2px 0px 18px rgba(255, 255, 255, 1);
    }
    .embedded-video-content {
      strong {
        color: $herit3;
      }
    }

    button.validate {
      color: #033b58;
    }
    .answer-group {
      button {
        border: solid 2px $herit3;
        color: $herit3;
        &:hover {
          //background: darken($herit1, 5);
        }
        &.selected {
          background-color: $herit3;
          color: white;
          box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.6);
          //border: solid 5px $herit1;
          font: {
            family: 'MontserratBold';
          }
        }
      }
    }
    .question-title {
      background-color: lighten($herit3, 40);
    }
    .quiz-question {
      p {
        color: #fff;
        background-color: $herit3;
      }
    }
    .embedded-content {
      .bool-title {
        color: $herit3;
        border-left: 0.1em solid $herit3;
      }
      .rich-text-content {
        &:first-child {
          border-right-color: $herit3;
        }
      }
    }
    .embedded-content-video {
      .bool-title {
        color: $herit3;
        border-left: 0.1em solid $herit3;
      }
    }
    .embedded-content,
    .embedded-content-video {
      .rich-text {
        .arrow::before {
          content: '';
          position: absolute;
          width: 3.2em;
          height: 2.2em;
          right: -2em;
          bottom: 0;
          background-image: url('../../img/layout/buttons/herit3_arrow.png');
          background-repeat: no-repeat;
          background-size: contain;
          @include mq($until: desktop) {
            transform: rotate(90deg);
            right: -2em;
          }
        }
      }
    }
    .resources-wrapper {
      .resource-link {
        color: #26845c;
        background-color: lighten($herit3, 40);
        &::before {
          background-image: url('../../img/layout/resources/herit3_resources_icon.png');
        }
      }
    }

    .return {
      background: $herit3;
    }
    .slider-button {
      &.next {
        background-image: url('../../img/layout/buttons/herit3_next.png');
      }
      &.previous {
        background-image: url('../../img/layout/buttons/herit3_prev.png');
      }
    }
  }
  //////herit4//////
  &.herit4 {
    color: #3f235f;
    .close {
      background-image: url('../../img/layout/buttons/herit4_close.png');
    }
    header {
      &.modal-title {
        h5 {
          color: $herit4;
          span {
            border-bottom: 3px solid $herit4;
            padding-left: 2em;
          }
        }
      }
    }
    .custom-background-color {
      background-clip: padding-box;
      background-color: lighten(#3f235f, 60);
      -webkit-box-shadow: inset 0px -2px 0px 18px rgba(255, 255, 255, 1);
      -moz-box-shadow: inset 0px -2px 0px 18px rgba(255, 255, 255, 1);
      box-shadow: inset 0px -2px 0px 18px rgba(255, 255, 255, 1);
    }
    .embedded-video-content {
      strong {
        color: $herit4;
      }
    }

    button.validate {
      color: #3f235f;
    }
    .answer-group {
      button {
        border: solid 2px $herit4;
        color: $herit4;
        &:hover {
          //background: darken($herit1, 5);
        }
        &.selected {
          background-color: $herit4;
          color: white;
          box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.6);
          //border: solid 5px $herit1;
          font: {
            family: 'MontserratBold';
          }
        }
      }
    }
    .question-title {
      background-color: lighten(#3f235f, 60);
    }
    .quiz-question {
      p {
        color: #fff;
        background-color: $herit4;
      }
    }
    .embedded-content {
      .bool-title {
        color: $herit4;
        border-left: 0.1em solid $herit4;
      }
      .rich-text-content {
        &:first-child {
          border-right-color: $herit4;
        }
      }
    }
    .embedded-content-video {
      .bool-title {
        color: $herit4;
        border-left: 0.1em solid $herit4;
      }
    }
    .embedded-content,
    .embedded-content-video {
      .rich-text {
        .arrow::before {
          content: '';
          position: absolute;
          width: 3.25em;
          height: 2.2em;
          right: -4.5em;
          background-image: url('../../img/layout/buttons/herit4_arrow.png');
          background-repeat: no-repeat;
          background-size: contain;
          @include mq($until: desktop) {
            transform: rotate(90deg);
          }
        }
      }
    }
    .resources-wrapper {
      .resource-link {
        color: #3f235f;
        background-color: lighten(#e6a30d, 35);
        &::before {
          background-image: url('../../img/layout/resources/herit4_resources_icon.png');
        }
      }
    }
    .slider-button {
      &.next {
        background-image: url('../../img/layout/buttons/herit4_next.png');
      }
      &.previous {
        background-image: url('../../img/layout/buttons/herit4_prev.png');
      }
    }
  }
  //////herit5//////
  &.herit5 {
    color: #033b58;
    .close {
      background-image: url('../../img/layout/buttons/herit5_close.png');
    }
    header {
      &.modal-title {
        h5 {
          color: $herit5;
          span {
            border-bottom: 3px solid $herit5;
            padding-left: 2em;
            @include mq($until: desktop) {
              padding-left: 1em;
              border-bottom: inherit;
            }
          }
          @include mq($until: desktop) {
            border-bottom: 3px solid $herit5;
          }
        }
      }
    }
    .custom-background-color {
      background-clip: padding-box;
      background-color: #b3c4cd;
      -webkit-box-shadow: inset 0px -2px 0px 18px rgba(255, 255, 255, 1);
      -moz-box-shadow: inset 0px -2px 0px 18px rgba(255, 255, 255, 1);
      box-shadow: inset 0px -2px 0px 18px rgba(255, 255, 255, 1);
    }
    .embedded-video-content {
      strong {
        color: $herit5;
      }
    }

    button.validate {
      color: #033b58;
    }
    .answer-group {
      button {
        border: solid 2px $herit5;
        color: $herit5;
        &:hover {
          //background: darken($herit1, 5);
        }
        &.selected {
          background-color: $herit5;
          color: white;
          box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.6);

          font: {
            family: 'MontserratBold';
          }
        }
      }
    }
    .question-title {
      background-color: #b3c4cd;
    }
    .quiz-question {
      p {
        color: #fff;
        background-color: $herit5;
      }
    }
    .embedded-content {
      .bool-title {
        color: $herit5;
        border-left: 0.1em solid $herit5;
      }
      .rich-text-content {
        &:first-child {
          border-right-color: $herit5;
        }
      }
    }
    .embedded-content-video {
      .bool-title {
        color: $herit5;
        border-left: 0.1em solid $herit5;
      }
    }
    .embedded-content,
    .embedded-content-video {
      .rich-text {
        .arrow::before {
          content: '';
          position: absolute;
          width: 3.25em;
          height: 2.2em;
          right: -4.5em;
          background-image: url('../../img/layout/buttons/herit5_arrow.jpg');
          background-repeat: no-repeat;
          background-size: contain;
          @include mq($until: desktop) {
            transform: rotate(90deg);
          }
        }
        .custom-image-before {
          position: relative;
          margin-left: 5em;
          padding-top: 1em;
          padding-bottom: 1em;

          &::before {
            position: absolute;
            left: -6em;
            top: 0;
            content: '';
            width: 5em;
            height: 6.5em;
            background-image: url('../../img/layout/icons/laws-icon.jpg');
            background-repeat: no-repeat;
            background-size: cover;
            border-right-color: $herit5;
            border-right-width: 2px;
            border-right-style: solid;
          }
        }
      }
    }
    .resources-wrapper {
      .resource-link {
        color: $herit5;
        background-color: lighten($herit5, 40);
        &::before {
          background-image: url('../../img/layout/resources/herit5_resources_icon.png');
        }
      }
    }
    .slider-button {
      &.next {
        background-image: url('../../img/layout/buttons/herit5_next.png');
      }
      &.previous {
        background-image: url('../../img/layout/buttons/herit5_prev.png');
      }
    }
  }
  //////herit6//////
  &.herit6 {
    color: $herit6;
    .close {
      background-image: url('../../img/layout/buttons/herit2_close.png');
    }
    header {
      &.modal-title {
        h5 {
          color: $herit6;
          span {
            border-bottom: 3px solid $herit6;
            padding-left: 2em;
          }
        }
      }
    }
    .custom-background-color {
      background-clip: padding-box;
      background-color: #b3c4cd;
      -webkit-box-shadow: inset 0px -2px 0px 18px rgba(255, 255, 255, 1);
      -moz-box-shadow: inset 0px -2px 0px 18px rgba(255, 255, 255, 1);
      box-shadow: inset 0px -2px 0px 18px rgba(255, 255, 255, 1);
    }
    .embedded-video-content {
      strong {
        color: $herit6;
      }
    }
    &:not(.final-quiz-start) {
      button.validate {
        color: #004157;
      }
    }

    .answer-group {
      button {
        border: solid 2px #004157;
        color: #004157;
        &:hover {
          //background: darken($herit1, 5);
        }
        &.selected {
          background-color: #004157;
          color: white;
          box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.6);
          font: {
            family: 'MontserratBold';
          }
        }
      }
    }

    .question-title {
      margin-top: 2em;
      margin-bottom: 0;
      position: relative;
      background-color: lighten($herit6, 30);
      &::before {
        content: '';
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        background-image: url('../../img/layout/icons/final-quiz-icon.png');
        background-repeat: no-repeat;
        background-size: contain;
        width: 3.5em;
        height: 3.5em;
        position: absolute;
        top: -3.2em;
      }
    }
    .quiz-question {
      p {
        color: #004157;
        background-color: inherit;
      }
    }
    .embedded-content {
      .bool-title {
        display: none;
      }
      .rich-text-content {
        color: #004157;
        margin-top: 2em;
        &:first-child {
          border-right-color: #004157;
        }
      }
    }
    .embedded-content-video {
      .bool-title {
        color: $herit6;
        border-left: 0.1em solid $herit6;
      }
    }
    .embedded-content {
      .rich-text {
        .final.question-bloc {
        }
        .final-clue-bloc {
          text-transform: uppercase;
          font: {
            family: 'MontserratBold';
          }
        }
      }
    }
    .slider-button {
      &.next {
        background-image: url('../../img/layout/buttons/herit2_next.png');
      }
      &.previous {
        display: none;
      }
    }
    .fail-content,
    .passed-content {
      h1 {
        background-color: lighten($herit6, 30);
      }
      .score {
        color: #28be93;
      }
      .info {
        position: relative;
        color: #004157;
        margin-top: 9em;
        background-clip: padding-box;
        background-color: #c0eddf;
        -webkit-box-shadow: inset 0px 3px 0px 10px rgba(255, 255, 255, 1);
        -moz-box-shadow: inset 0px 3px 0px 10px rgba(255, 255, 255, 1);
        box-shadow: inset 0px 3px 0px 10px rgba(255, 255, 255, 1);
        &::before {
          position: absolute;
          top: -7em;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          content: '';
          width: 5em;
          height: 6.5em;
          background-image: url('../../img/layout/icons/laws-icon.jpg');
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
    .passed-content {
      .score {
        position: relative;
        margin-bottom: 6em;
        &::after {
          position: absolute;
          bottom: -5.5em;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          content: '';
          width: 5.5em;
          height: 5.5em;
          background-image: url('../../img/layout/icons/final-quiz-icon.png');
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
      button {
        background-color: $herit6;
      }
    }
  }
  &.signup-form{
    .close {
      background-image: url('../../img/layout/buttons/herit2_close.png');
    }
  }
}
