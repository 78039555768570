$primary: #0b3c59;
$secondary: #4db7aa;
$color3: #31414f;
$color4: #ffbd20;
$color5: #687a8a;
$color6: #ec7520;
$color7: #19029a;

$herit1: #033b58;
$herit5: #e95c0c;
$herit2: #3f235f;
$herit3: #338f8f;
$herit4: #e6a30d;
$herit6: #f09138;
$grey1: #76777b;
$grey2: #333333;

$spacing: 25px;

$general: (
  background: #fff,
  color: #fff,
);

$footer: (
  background: #fff,
  color: $primary,
);

$header: (
  background: #fff,
  color: $primary,
  height: 85px,
);

$menu: (
  shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.7),
  padding: $spacing / 1.5,
);

$box: (
  link-thickness: 7px,
  margin: 10px,
  mobile-height: 60px,
  mobile-margin: 5px,
  radius: 10px,
  shadow: 10px 10px 30px -8px rgba(0, 0, 0, 0.6),
  size: 150px,
);

$button: (
  background: $secondary,
  color: #fff,
  font-size: 1.2em,
  margin: $spacing,
  padding-top: 10px,
  padding-left: $spacing * 1.5,
);
