@import '../../../../style/theme.scss';
@import '../../../../../node_modules/sass-mq/mq';
@import '../../../../style/mixins.scss';

.modal-slider {
  align-items: center;
  background: #fff;
  height: 490px;
  max-height: 100vh;
  position: relative;
  border-radius: 10px;

  @include mq($until: desktop) {
    height: auto;
    width: auto;
    max-height: 90vh;

    &.stand-alone {
      position: initial;

      // @include ie10-11 {
      @include mq($until: desktop) {
        max-height: none;
        flex-wrap: wrap;
      }
      // }
    }

    &:not(.stand-alone) {
      max-height: none;

      @include mq($until: desktop) {
        padding-bottom: $spacing * 3;
      }
    }
  }
  .slider-button {
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    cursor: pointer;
    position: absolute;
    top: 45%;
    width: 3em;
    height: 3em;
    border-radius: 50%;
    @include mq($until: desktop) {
      bottom: 1.5em;
      top: inherit;
    }
    &.next,
    &.back {
      right: -3.5em;
      @include mq($until: desktop) {
        right: 1.5em;
      }
    }
    &.previous {
      left: -3.5em;
      @include mq($until: desktop) {
        left: 1.5em;
      }
    }
    &.extra {
      right: 50%;
      bottom: 0;
      background-color: red;
    }
  }
}
