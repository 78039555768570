@import '../../style/theme.scss';
@import '../../../node_modules/sass-mq/mq';
@import '../../style/mixins.scss';
@import '../../style/colors_herit.scss';

.toaster {
  display: flex;
  width: 80%;
  margin: auto;
  @include mq($until: desktop) {
    width: 100%;
    flex-direction: column;
  }
  p {
    width: 80%;
    padding: 1em;
    color: $primary;
    font-style: italic;
    text-align: center;
    margin: auto;
    font: {
      size: 1em;
    }
    @include mq($until: desktop) {
      width: 90%;
    }
  }
  button {
    display: block;
    margin: auto;
    background-color: #fff;
    color: #ec7520;
    display: block;
    border: 4px solid #ec7520;
    border-radius: 30px;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
    padding: map-get($map: $button, $key: padding-top)
      map-get($map: $button, $key: padding-left);
    font: {
      size: 1em;
      weight: 700;
    }
    &:hover {
      color: #fff;
      background-color: #ec7520;
    }
    @include mq($until: desktop) {
      margin-bottom: 0.5em;
    }
  }
}
