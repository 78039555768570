
@import '../../style/theme.scss';

.circled-plus {
  background: $color6;
  border-radius: 50%;
  display: inline-block;
  line-height: 1em;
  padding: 2px;
  text-align: center;
  height: 1em;
  width: 1em;
}