@import '../../style/theme.scss';
@import '../../style/mixins.scss';
@import '../../../node_modules/sass-mq/mq';

.home {
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;

  @include mq($until: desktop) {
    justify-content: center;
    padding-bottom: 1em;
  }
  .box {
    @include mq($until: desktop) {
      order: 0;
    }
    &.tail {
      @include mq($until: desktop) {
        order: 1;
      }
    }
  }
}
