@import '../../../../style/theme.scss';
@import '../../../../style/constants.scss';
@import '../../../../../node_modules/sass-mq/mq';
@import '../../../../style/mixins.scss';

.fail-content {
  width: 940px;
  height: 490px;
  @include mq($until: desktop) {
    width: 100%;
    height: 100%;
  }
  h1 {
    width: 90%;
    margin-top: 0.1em;
    margin-bottom: 0.2em;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    text-transform: uppercase;
    -webkit-box-shadow: inset 0px 35px 0px 1px rgba(255, 255, 255, 1);
    -moz-box-shadow: inset 0px 35px 0px 1px rgba(255, 255, 255, 1);
    box-shadow: inset 0px 35px 0px 1px rgba(255, 255, 255, 1);
    background-clip: padding-box;
    font: {
      size: 3.5em;
      family: 'MontserratBold';
    }
    @include mq($until: desktop) {
      width: 90%;
    }
  }
  p {
    text-align: center;
    font: {
      size: 1.5em;
      family: 'MontserratBold';
    }
  }
  .info {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    font: {
      size: 1.2em;
    }
    @include mq($until: desktop) {
      width: 90%;
    }
  }
  .button-group {
    display: flex;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-around;
    margin-top: 2em;
    @include mq($until: desktop) {
      flex-direction: column;
      width: 100%;
      margin-top: 2em;
    }

    button {
      color: #fff;
      text-transform: uppercase;
      padding: 1em;
      border-radius: 25px;
      background-color: #ee9037;
      width: 40%;
      @include mq($until: desktop) {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2em;
      }
      font: {
        size: 1em;
        family: 'MontserratBold';
      }
    }
  }
}
