@import '../../../node_modules/sass-mq/mq';

.player-wrapper {
  display: flex;
  justify-content: center;
  @include mq($until: desktop) {
    margin-top: 2em;
    display: block;
    width: 100%;
  }
}

.react-player {
  top: 0;
  left: 0;

  @include mq($until: desktop) {
    max-width: none;
    max-height: none;
  }
}
