@import '../../../../style/theme.scss';
@import '../../../../style/constants.scss';
@import '../../../../../node_modules/sass-mq/mq';
@import '../../../../style/mixins.scss';

.final-quiz-start {
  width: 940px;
  height: 490px;
  padding-top: 10em;
  margin-top: 0.5em;
  @include mq($until: desktop) {
    width: 100%;
    height: 100%;
    padding-top: 9em;
  }
  .intro {
    &::before {
      content: '';
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      background-image: url('../../../../img/layout/icons/final-quiz-icon.png');
      background-repeat: no-repeat;
      background-size: contain;
      width: 5.5em;
      height: 5.5em;
      position: absolute;
      top: -6.2em;
      @include mq($until: desktop) {
        top: -8.2em;
      }
    }
    position: relative;
    text-align: center;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
    margin-bottom: 1em;
    -webkit-box-shadow: inset 0px 15px 0px 1px rgba(255, 255, 255, 1);
    -moz-box-shadow: inset 0px 15px 0px 1px rgba(255, 255, 255, 1);
    box-shadow: inset 0px 15px 0px 1px rgba(255, 255, 255, 1);
    background-color: lighten($herit6, 30);
    background-clip: padding-box;
    text-transform: uppercase;
    font: {
      family: 'MontserratBold';
      size: 1.6em;
      weight: 700;
    }
    @include mq($until: desktop) {
      text-align: center;
      width: 90%;
      font: {
        size: 1.2em;
        weight: 700;
      }
    }
  }
  .rules {
    text-align: center;
    width: 70%;
    margin-right: auto;
    margin-left: auto;
    color: #004157;
    font: {
      family: 'MontserratBold';
      size: 1.2em;
      weight: 700;
    }
    @include mq($until: desktop) {
      text-align: center;
      width: 90%;
    }
  }
  .validate {
    background-color: $herit6;
    margin-top: 4em;
    color: white !important;
    display: block;
    border-radius: 20px;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
    padding: map-get($map: $button, $key: padding-top)
      map-get($map: $button, $key: padding-left);
    font: {
      size: map-get($map: $button, $key: font-size);
      weight: 700;
    }
    &:hover {
      -webkit-box-shadow: 0px 0px 16px 3px rgba(0, 0, 0, 1);
      -moz-box-shadow: 0px 0px 16px 3px rgba(0, 0, 0, 1);
      box-shadow: 0px 0px 16px 3px rgba(0, 0, 0, 1);
    }
    @include mq($until: desktop) {
      margin-top: 2em;
    }
  }
}
