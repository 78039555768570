@import '../../style/theme.scss';
@import '../../../node_modules/sass-mq/mq';

.resources-wrapper {
  display: flex;
  width: 940px;
  min-height: 490px;
  flex-direction: column;
  align-items: center;
  padding: 0;
  @include mq($until: desktop) {
    width: 100vw;
  }
  .resource-link {
    display: block;
    position: relative;
    width: 60%;
    border-radius: 10px;
    height: 2em;

    font: {
      family: 'MontserratBold';
      size: 1.1em;
      weight: 100;
    }
    margin: $spacing / 4 $spacing;
    padding-top: 0.9em;
    padding-bottom: 0.9em;
    padding-right: $spacing / 1.5;
    padding-left: 3em;
    text-align: left;
    &:hover {
      transition: all 0.2s;
      width: 55%;
    }
    &:first-of-type {
      margin-top: $spacing;
    }
    &:last-of-type {
      margin-bottom: $spacing;
    }
    &::before {
      border-radius: 50%;
      position: absolute;
      left: -3em;
      top: -1em;
      content: '';
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 6em;
      height: 6em;
      @include mq($until: desktop) {
        width: 6.8em;
        height: 6.8em;
      }
    }
    @include mq($until: desktop) {
      width: 70%;
      margin-left: 3em;
      height: 2.5em;
      padding-top: 0.7em;
      font: {
        size: 0.8em;
        weight: 100;
      }
    }
  }
}
