@import '../../node_modules/sass-mq/mq';

@mixin ie10-11 {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}
@mixin border-box {
  box-sizing: border-box;
}

@mixin horizontal-center {
  margin: {
    left: auto;
    right: auto;
  }
}

@mixin horizontal-centered-block {
  display: block;
  margin: {
    top: 0;
    bottom: 0;
  }
  @include horizontal-center();
}

@mixin transform-skewX($angle: 170deg) {
  transform: skewX($angle);
}

@mixin desktop-max-width {
  max-width: $desktop-max-width;
}

@mixin blank-link($length, $direction: 'horizontal', $reverse: false) {
  position: relative;
  z-index: 200;

  &:after {
    @if $direction == 'horizontal' {
      height: map-get($map: $box, $key: link-thickness);
      width: $length * 2;
      @if $reverse {
        left: -$length * 2;
      } @else {
        right: -$length * 2;
      }
      @include mq($from: desktop) {
        top: 50%;
      }
    } @else {
      height: $length * 2;
      width: map-get($map: $box, $key: link-thickness);
      bottom: -$length * 2;
      right: 50%;
      left: 50%;
    }
    background: #fff;
    box-shadow: 10px 10px 30px -8px rgb(0, 0, 0);
    content: '';
    display: block;
    position: absolute;
    z-index: 100;
  }
}

@mixin adornment-helper($color) {
  content: '';
  display: block;
  height: 0;
  position: absolute;
  width: 0;
  z-index: -1;
}

@mixin triangle-helper($direction, $size, $color) {
  @if $direction == 'top' {
    border: {
      top: $size solid $color;
      left: $size solid transparent;
      right: $size solid transparent;
    }
  } @else if $direction == 'left' {
    border: {
      left: $size solid $color;
      top: $size solid transparent;
      bottom: $size solid transparent;
    }
  } @else if $direction == 'bottom' {
    border: {
      bottom: $size solid $color;
      left: $size solid transparent;
      right: $size solid transparent;
    }
  } @else if $direction == 'right' {
    border: {
      right: $size solid $color;
      top: $size solid transparent;
      bottom: $size solid transparent;
    }
  }
}

@mixin triangle($direction, $color, $size: 2) {
  position: relative;

  @if $direction == 'top' or $direction == 'left' {
    &:before {
      @include adornment-helper($color);
      @include triangle-helper(
        $direction,
        map-get($map: $box, $key: radius) * $size,
        $color
      );
      @if $direction == 'top' {
        top: 0;
        // @include ie10-11() {
        left: 50%;
        transform: translateX(-50%);
        // }
      } @else if $direction == 'left' {
        left: 0;
        // @include ie10-11() {
        top: 50%;
        transform: translateY(-50%);
        // }
      }
    }
  } @else if $direction == 'bottom' or $direction == 'right' {
    &:after {
      @include adornment-helper($color);
      @include triangle-helper(
        $direction,
        map-get($map: $box, $key: radius) * $size,
        $color
      );
      @if $direction == 'bottom' {
        bottom: 0;
        // @include ie10-11() {
        left: 50%;
        transform: translateX(-50%);
        // }
      } @else if $direction == 'right' {
        right: 0;
        // @include ie10-11() {
        top: 50%;
        transform: translateY(-50%);
        // }
      }
    }
  }
}

@mixin diamond($color) {
  position: relative;
  overflow: hidden;

  &:before {
    @include adornment-helper($color);
    bottom: 50%;
    left: 50%;
    transform: translateX(-50%);

    @include mq($until: desktop) {
      @include triangle-helper(
        'bottom',
        map-get($map: $box, $key: mobile-height) * 2.4,
        $color
      );
      @include ie10-11() {
        @include triangle-helper(
          'bottom',
          map-get($map: $box, $key: mobile-height) * 3.4,
          $color
        );
      }
    }

    @include mq($from: desktop) {
      @include triangle-helper(
        'bottom',
        map-get($map: $box, $key: size) / 1.8,
        $color
      );
    }
  }

  &:after {
    @include adornment-helper($color);
    top: 50%;
    left: 50%;
    transform: translateX(-50%);

    @include mq($until: desktop) {
      @include triangle-helper(
        'top',
        map-get($map: $box, $key: mobile-height) * 2.4,
        $color
      );
      @include ie10-11() {
        @include triangle-helper(
          'top',
          map-get($map: $box, $key: mobile-height) * 3.4,
          $color
        );
      }
    }

    @include mq($from: desktop) {
      @include triangle-helper(
        'top',
        map-get($map: $box, $key: size) / 1.8,
        $color
      );
    }
  }
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}
