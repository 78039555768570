@import '../../style/theme.scss';
@import '../../style/mixins.scss';
@import '../../../node_modules/sass-mq/mq';
@import '../../style/reset.scss';

.box {
  text-transform: uppercase;
  align-items: center;
  background: $secondary;
  box-shadow: map-get($map: $box, $key: shadow);
  color: #fff;
  display: flex;
  height: map-get($map: $box, $key: size);
  justify-content: center;
  line-height: 1.4em;
  margin: map-get($map: $box, $key: margin);
  position: relative;
  text-align: center;
  width: map-get($map: $box, $key: size);
  z-index: 200;
  @media (max-width: 1500px) {
    width: 130px;
    height: 130px;
    margin: 8px;
    font-size: 0.75em;
  }

  font: {
    size: 0.9em;
    weight: 800;
  }

  @include mq($until: desktop) {
    font-size: 1em;
    height: map-get($map: $box, $key: mobile-height);
    margin: map-get($map: $box, $key: mobile-margin);
    width: 80%;
  }

  &:hover:not(.empty) {
    cursor: pointer;
    box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.6);
    transition: ease-in 0.1s;
  }
  &.rounded {
    border-radius: map-get($map: $box, $key: radius);
  }

  &.empty {
    background: white;
    opacity: 0.8;

    @extend .hidden-mobile;

    &:hover {
      cursor: default;
    }
  }

  .locked-filter,
  .visited-filter {
    border-radius: inherit;
    height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    @include mq($until: desktop) {
      width: 100%;
    }
  }

  figure.illustration {
    height: 100%;
    position: relative;
    width: 100%;

    &:hover figcaption {
      background: darken($color3, 10);
    }

    img {
      height: 100%;
      width: 100%;
    }

    figcaption {
      align-items: center;
      justify-content: center;
      background: $color3;
      bottom: 0;
      display: flex;
      height: $spacing;
      left: 0;
      position: absolute;
      right: 0;
      width: 100%;
    }

    @include mq($until: desktop) {
      position: initial;

      img {
        display: none;
      }

      figcaption {
        position: initial;
        height: 100%;
        width: 100%;
      }
    }
  }

  // special styles
  &.head {
    &:hover,
    &:focus,
    &:active {
      background: darken($color3, 10);
    }
  }

  &.tail {
    background-color: #fff;
    color: $herit5;
    &:hover,
    &:focus,
    &:active {
      background: darken(#fff, 10);
    }
  }

  &.chevron-top {
    @include triangle('top', $color3, 3);

    @include mq($until: desktop) {
      @include triangle('top', $color3, 1.6);
    }
  }

  &.diamond {
    @include diamond($color3);
  }
}
