@import "../../../../style/theme.scss";
@import "../../../../style/constants.scss";
@import "../../../../../node_modules/sass-mq/mq";
@import "../../../../style/mixins.scss";

.specific-example {
  div p {
    font-size: 2em;
  }
  h5 {
    color: red;
  }
  legend {
    font-size: 1.5em;
  }
}
