@import '../../style/theme.scss';
@import '../../../node_modules/sass-mq/mq';
@import '../../style/mixins.scss';
@import '../../style/colors_herit.scss';

.video-background {
  top: 35px;
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  z-index: -999;
  video {
    @media screen and (max-aspect-ratio: 16/9) {
      height: 100vh;
      max-height: 100vh;
      min-height: 100vh;
      top: 0;
      width: 100%;
    }
  }
  @media screen and (max-aspect-ratio: 16/9) {
    top: 0;
  }
}
.global {
  display: flex;
  transition: all 0.8s;
  width: 100%;

  @include mq($until: desktop) {
    background-color: #4db7aa;
  }

  .page {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    text-align: center;

    width: auto;
    @include ie10-11 {
      text-align: center;
    }

    @include mq($until: desktop) {
      width: 100%;
    }

    .container {
      justify-content: flex-end;

      // @include ie10-11 {
      display: inline-block;
      margin-left: 90px;
      text-align: right;
      position: relative;

      @include mq($until: desktop) {
        margin-left: 0;
      }
      // }

      .content {
        background-repeat: no-repeat, repeat;
        background-size: 100% auto;
        height: 100%;
        padding: $spacing/2 0;

        @include mq($from: desktop) {
          background: none !important;
        }

        @include mq($until: desktop) {
          width: 100%;
        }

        // @include ie10-11 {
        display: inline-block;
        // }
      }
    }
  }
}
