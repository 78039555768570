@import '../../../../style/theme.scss';
@import '../../../../style/constants.scss';
@import '../../../../../node_modules/sass-mq/mq';
@import '../../../../style/mixins.scss';

iframe {
  width: 940px;
  border: none;
  height: 490px;
  @include mq($until: desktop) {
    width: 100%;
  }
}
