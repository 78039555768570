$fontsPath: '../fonts/';

@font-face {
  font-family: 'MontserratRegular';
  src: url('#{$fontsPath}Montserrat/MontserratRegular.eot');
  src: url('#{$fontsPath}Montserrat/MontserratRegular.woff2'),
    url('#{$fontsPath}Montserrat/MontserratRegular.woff'),
    url('#{$fontsPath}Montserrat/MontserratRegular.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'MontserratLight';
  src: url('#{$fontsPath}Montserrat/MontserratLight.eot');
  src: url('#{$fontsPath}Montserrat/MontserratLight.woff2'),
    url('#{$fontsPath}Montserrat/MontserratLight.woff'),
    url('#{$fontsPath}Montserrat/MontserratLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'MontserratBold';
  src: url('#{$fontsPath}Montserrat/MontserratExtraBold.eot');
  src: url('#{$fontsPath}Montserrat/MontserratExtraBold.woff2'),
    url('#{$fontsPath}Montserrat/MontserratExtraBold.woff'),
    url('#{$fontsPath}Montserrat/MontserratExtraBold.ttf') format('truetype');
  font-weight: 200;
}
