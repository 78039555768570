@import '../../../../style/theme.scss';
@import '../../../../style/constants.scss';
@import '../../../../../node_modules/sass-mq/mq';
@import '../../../../style/mixins.scss';

.quick-access-container,.access-container {
  input {
    padding: 10px 20px;
    text-transform: uppercase;
    border: none;
    color: #44285d;
    background-color: #f1f1f1;
    @include mq($until: desktop) {
      width:8em;
    }
    font: {
      size: 0.9em;
      weight: 700;
    }
  }

  form {
    width: 60%;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-right: auto;
    margin-left: auto;
    padding-top: 1em;
    @include mq($until: desktop) {
      width:100%;
    }
    button {
      background-color: #44285d;
      color: #fff;
      border: 4px solid #44285d;
      border-radius: 30px;
      text-transform: uppercase;
      padding: 0.4em;
      margin-left: 2em;
      min-width: 8em;
      font: {
        size: map-get($map: $button, $key: font-size);
        weight: 700;
      }
      &:hover {
        color: #44285d;
        background-color: #fff;
      }
    }
  }
}
