@import '../../../../style/theme.scss';
@import '../../../../../node_modules/sass-mq/mq';
@import '../../../../style/mixins.scss';

.quiz-question {
  width: 940px;
  @include mq($until: desktop) {
    width: 100%;
  }
  .adornment {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: $spacing / 2 0;
    .question-title {
      margin-top: 1em;
      margin-bottom: 1em;
      -webkit-box-shadow: inset 0px 15px 0px 1px rgba(255, 255, 255, 1);
      -moz-box-shadow: inset 0px 15px 0px 1px rgba(255, 255, 255, 1);
      box-shadow: inset 0px 15px 0px 1px rgba(255, 255, 255, 1);
      background-clip: padding-box;
      font: {
        family: 'MontserratBold';
        size: 1.8em;
        weight: 700;
      }
      @include mq($until: desktop) {
        text-align: center;
        margin-right: 0.5em;
        margin-left: 0.5em;
        font: {
          size: 1.2em;
          weight: 700;
        }
      }
    }
  }
  p {
    border-radius: 10px;
    text-align: center;
    min-height: 2.6em;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    font: {
      size: 1.2em;
    }
    padding-top: 1em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-bottom: 1em;
    margin-bottom: 2em;
    @include mq($until: desktop) {
      width: 80%;
      font: {
        size: 1.2em;
      }
    }
  }
  .answer-group {
    position: absolute;
    width: 100%;
    bottom: 36%;
    z-index: 100;
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    padding-left: 2em;
    padding-right: 2em;
    @include mq($until: desktop) {
      margin-top: $spacing / 2;
      flex-flow: column;
      align-items: center;
      position: inherit;
      width: inherit;
      bottom: inherit;
    }
    &.twoAnswers {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
    button {
      background-color: #fff;
      border-radius: 2em;
      text-transform: uppercase;
      min-height: 2.5em;
      z-index: 100;
      font: {
        size: map-get($map: $button, $key: font-size);
        weight: 700;
      }
      margin: map-get($map: $button, $key: margin) / 3
        map-get($map: $button, $key: margin);
      padding: map-get($map: $button, $key: padding-top)
        map-get($map: $button, $key: padding-left);
      transition: all 0.2s;

      &:hover {
        color: #fff;
      }

      &.selected {
        color: #fff;
      }
      &.fixed-width {
        min-width: 200px;
        max-width: 340px;
      }
    }
  }

  .quiz-illustration {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 20vh;
    margin-top: map-get($map: $button, $key: margin) * 1.5;
    min-height: 200px;
    width: 100%;
  }

  .validate {
    background-color: #fff;
    margin-top: 7em;
    display: block;
    border-radius: 20px;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
    padding: map-get($map: $button, $key: padding-top)
      map-get($map: $button, $key: padding-left);
    font: {
      size: map-get($map: $button, $key: font-size);
      weight: 700;
    }
    &:hover {
      -webkit-box-shadow: 0px 0px 16px 3px rgba(0, 0, 0, 1);
      -moz-box-shadow: 0px 0px 16px 3px rgba(0, 0, 0, 1);
      box-shadow: 0px 0px 16px 3px rgba(0, 0, 0, 1);
    }

    @include mq($until: desktop) {
      margin-top: 8em;
    }
  }

  .quiz-footer {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    bottom: 0;
    min-height: 13em;
    &.large {
      @include mq($until: desktop) {
        min-height: 14.5em;
      }
      .validate {
        @include mq($until: desktop) {
          margin-top: 9em;
        }
      }
    }
  }
}
