@import '../../style/mixins.scss';
@import '../../style/theme.scss';
@import '../../../node_modules/sass-mq/mq';

$playlist-width: 40;
$playlist-left-closed: 33;

.video-playlist {
  position: relative;
  overflow: hidden;

  button {
    font: {
      family: 'MontserratRegular';
    }
  }

  .video-playlist-container {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    width: $playlist-width * 1%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    box-sizing: border-box;

    @include transition(left 0.5s, background-color 0.5s);

    @include mq($until: desktop) {
      position: relative;
      width: 100%;
      background: black;
    }

    .video-playlist-content {
      padding: 2em;
      position: relative;

      .close-playlist {
        position: absolute;
        top: 0;
        right: 0;

        button {
          color: red;
          font: {
            size: 1.5em;
            weight: bold;
          }
          margin: 0 0.5em;
        }

        @include mq($until: desktop) {
          display: none;
        }
      }

      .buttons {
        button {
          color: white;
          margin-left: auto;
          margin-right: auto;
          width: 100%;

          &,
          span {
            display: block;
          }
          span {
            &.title {
              font: {
                size: 1.2em;
                weight: bold;
              }
              text-transform: uppercase;
            }
            &.description {
              font: {
                weight: normal;
              }
            }
          }

          &:hover {
            span {
              text-decoration: underline;
            }
          }
          &.active {
            background: rgba(255, 255, 255, 0.3);
          }
          &.visited {
            &:not(.active) {
              color: #c0c0c0;
            }
          }
          &:after {
            content: '*';
            color: red;
          }

          @include mq($until: desktop) {
            font-size: 1em;
          }
        }
      }
    }
  }

  @include mq($from: desktop) {
    &.closed {
      .video-playlist-container {
        background: none;
        left: $playlist-left-closed * -1%;
        pointer-events: none;
        overflow-y: inherit;

        .video-playlist-content {
          .close-playlist {
            left: ($playlist-left-closed / $playlist-width) * 100%;

            button {
              pointer-events: all;
            }
          }

          .buttons {
            display: none;
          }
        }
      }
    }
  }

  .player-wrapper {
    z-index: 99;
  }
}
