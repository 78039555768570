.container {
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: $desktop-max-width;
  @extend .clearfix;

  .content {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    width: 60%;
    max-width: 100%;

    @include mq($until: desktop) {
      justify-content: center;
      flex-direction: column;
    }
  }
}