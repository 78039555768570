@import "../../../../style/theme.scss";
@import "../../../../style/constants.scss";
@import "../../../../../node_modules/sass-mq/mq";
@import "../../../../style/mixins.scss";

.specific-list {
  min-width: 400px;
  padding: 15px;
  font-size: 2em;
  color: purple;
  background-color: pink;
}
