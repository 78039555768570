@import '../../../../style/theme.scss';
@import '../../../../style/constants.scss';
@import '../../../../../node_modules/sass-mq/mq';
@import '../../../../style/mixins.scss';

.passed-content {
  width: 940px;
  height: 490px;
  @include mq($until: desktop) {
    width: 100%;
    height: 100%;
  }
  h1 {
    width: 30%;
    margin-top: 0.1em;
    margin-bottom: 0.2em;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    text-transform: uppercase;
    -webkit-box-shadow: inset 0px 35px 0px 1px rgba(255, 255, 255, 1);
    -moz-box-shadow: inset 0px 35px 0px 1px rgba(255, 255, 255, 1);
    box-shadow: inset 0px 35px 0px 1px rgba(255, 255, 255, 1);
    background-clip: padding-box;
    font: {
      size: 3.5em;
      family: 'MontserratBold';
    }
    @include mq($until: desktop) {
      width: 90%;
    }
  }
  p {
    text-align: center;
    font: {
      size: 1.5em;
      family: 'MontserratBold';
    }
  }
  .passed-flex {
    display: flex;
    justify-content: space-evenly;
    @include mq($until: desktop) {
      flex-direction: column;
    }
    .form,
    .resource {
      width: 50%;
      padding-top: 1em;
      padding-left: 1em;
      padding-right: 1em;
      @include mq($until: desktop) {
        width: 100%;
      }
      h2 {
        color: #004157;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 1em;
        font: {
          size: 1em;
          family: 'MontserratBold';
        }
      }
    }
    .form {
      border-right: solid 3px #004157;
      @include mq($until: desktop) {
        border-right: inherit;
      }
      input {
        display: inline-block;
        padding: 10px 20px;
        border: none;

        color: rgba(0, 142, 198, 1);
        -o-text-overflow: clip;
        text-overflow: clip;
        background-color: lightgray;
        &.error {
          border: solid 1px red;
          box-sizing: border-box;
        }
      }

      label {
        display: inline-block;
        min-width: 20%;
        @include mq($until: desktop) {
          padding-right: 1em;
          min-width: 25%;
        }
      }
      fieldset {
        position: relative;
        padding-left: 2em;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        @include mq($until: desktop) {
          padding-left: 0;
        }
      }
      button {
        display: block;
        color: #fff;
        //margin-top: 7em;
        display: block;
        border-radius: 20px;
        text-transform: uppercase;
        margin-left: auto;
        margin-right: 1em;
        margin-top: 1em;
        padding: 10px 37.5px;
        font-size: 1em;
        font-weight: 700;
        @include mq($until: desktop) {
          margin-right: auto;
        }
      }
    }
    .resource {
      a {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 30%;
        margin-top: 3em;
      }
    }
  }
}
