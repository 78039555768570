@import '../../style/theme.scss';
@import '../../../node_modules/sass-mq/mq';

.header {
  background: map-get($map: $header, $key: background);
  min-height: map-get($map: $header, $key: height);
  width: 100%;
  display: flex;
  justify-content: space-around;
  @include mq($until: desktop) {
    flex-direction: column;
    height: 6em;
  }
  div {
    margin: 0;
    @include mq($until: desktop) {
      margin: 0 !important;
      justify-content: inherit;
    }
    &.logo {
      padding-top: 1.5em;
      position: relative;
      margin-left: $spacing;
      align-items: left;
      img {
        @include mq($until: desktop) {
          position: absolute;
          left: 0.5em;
          margin-left: inherit;
        }
      }
      @include mq($until: desktop) {
        height: 2em;
        padding-top: 0.5em;
      }
    }

    &.title {
      color: map-get($map: $header, $key: color);
      line-height: 1.652;
      text-align: right;
      padding-top: 1.3em;
      font: {
        family: 'MontserratBold';
        size: 1.2em;
      }
      @include mq($until: desktop) {
        font-size: 1em;
        padding-left: 0.5em;
        padding-bottom: 0.5em;
        line-height: 0.95;
        text-align: left;
        width: 100%;
      }
    }
  }
}
