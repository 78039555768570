@import '../../style/theme.scss';
@import '../../../node_modules/sass-mq/mq';
@import '../../style/mixins.scss';

.menu {
  border-radius: 10px;
  align-items: center;
  background: #fff;
  box-shadow: map-get($map: $menu, $key: shadow);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  position: absolute;
  transition: all ease-out 0.2s;
  z-index: 500;
  padding-bottom: 1em;

  // @include ie10-11 {
  display: inline-block;
  position: absolute;
  right: 0;
  top: $spacing;
  // }
  @media (max-width: 1500px) {
    top: inherit;
  }
  @include mq($from: desktop) {
    transform: translateX($spacing);
  }

  @include mq($until: desktop) {
    padding-bottom: 2em;
    top: 2em;
    left: 0;
  }
  .close {
    cursor: pointer;
    position: absolute;
    top: -2em;
    right: -2em;
    width: 4em;
    height: 4em;
    background-size: contain;
    background-repeat: no-repeat;
    text-align: center;
    @include mq($until: desktop) {
      right: 0;
    }
  }

  header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
    width: map-get($map: $box, $key: size) * 3 +
      map-get($map: $box, $key: margin) * 4;

    @include mq($until: desktop) {
      width: 100%;
    }

    .head {
      font: {
        family: 'MontserratRegular';
        size: 1.1em;
      }
      // @include ie10-11 {
      text-align: left;
      @include mq($until: desktop) {
        margin-left: 0;
      }
      // }

      .title {
        margin: 0;

        @include mq($until: desktop) {
          margin: {
            top: $spacing / 2;
            bottom: $spacing / 4;
            right: 0;
            left: 0;
          }
        }
      }
      .subtitle {
        width: 100%;
        font-size: 1em;
        min-height: 6.5em;
        padding: 2em 2em 1em 2em;
        box-sizing: border-box;
        @include mq($until: desktop) {
          font-size: 0.8em;
        }
      }

      h4.title {
        text-transform: uppercase;
        margin-top: $spacing;
        font: {
          family: 'MontserratBold';
          size: 1.3em;
        }
        @include mq($until: desktop) {
          font: {
            size: 1em;
          }
        }
      }
    }
  }

  .menu_content {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    @include mq($from: desktop) {
      min-width: 510px;
    }
    .box {
      border-radius: 10px;
      @include mq($until: desktop) {
        width: 100%;
        margin: map-get($map: $box, $key: mobile-margin) 1em;
      }
      &.play,
      &.quiz,
      &.resource {
        flex-direction: column;
        &::before {
          content: '';
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          display: block;
          width: 50px;
          height: 50px;
          margin-bottom: 1em;
          margin-left: auto;
          margin-right: auto;
          @include mq($until: desktop) {
            display: none;
          }
        }
      }
      &.play {
        &::before {
          background-image: url('../../../src/img/menu/play.png');
        }
      }
      &.quiz {
        &.empty {
          &::before {
            margin-bottom: 0em;
          }
        }
        &::before {
          background-image: url('../../../src/img/menu/quiz.png');
        }
      }
      &.resource {
        &::before {
          background-image: url('../../../src/img/menu/resource.png');
        }
      }
      &.empty {
        &.quiz {
          &::before {
            width: 75px;
            height: 75px;
          }
        }
      }
    }
  }
  .adornment {
    margin-top: $spacing * 2;
    position: relative;
    width: 100%;

    figure {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
    }
  }
}
