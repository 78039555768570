@import '../../../../style/theme.scss';
@import '../../../../style/constants.scss';
@import '../../../../../node_modules/sass-mq/mq';
@import '../../../../style/mixins.scss';

.embedded-image {
  width: 100%;
  height: 10em;
  background-size: content;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 0.2em;
  @include mq($until: desktop) {
    height: 5em;
  }
}
.no-embedded-image {
  height: 7em;
  margin-top: 0.2em;
  @include mq($until: desktop) {
    display: none;
  }
}
.content {
  &.embedded-content-video {
    max-width: $desktop-max-width;
    margin-top: 0.2em;
    width: 940px;
    @include mq($until: desktop) {
      width: 100%;
    }
    .rich-text {
      margin-right: 2.5em;
      margin-left: 2.5em;
      width: 45%;
      @include mq($until: desktop) {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
        padding-right: 1em;
        padding-left: 1em;
        padding-top: 0.5em;
        margin-bottom: 0.5em;
        margin-top: 1em;
      }
      p {
        font: {
          family: 'MontserratRegular';
          size: 1.1em;
        }
      }
    }
    .bool-title {
      text-transform: uppercase;
      box-sizing: border-box;
      text-align: left;
      padding-left: 0.8em;
      padding-top: 1em;
      height: 2.1em;
      margin-left: 1.4em;
      font: {
        family: 'MontserratBold';
        size: 2em;
      }
      @include mq($until: desktop) {
        margin-left: 1em;
        padding-top: 1em;
        font-size: 1.2em;
        height: 2em;
        font: {
          family: 'MontserratBold';
          size: 2em;
        }
      }
    }
    .no-bool-title {
      height: 0.5em;
      width: 100%;
    }
    .rich-content {
      position: relative;
      display: flex;
      padding-right: 2em;
      padding-left: 2em;
      padding-top: 1em;
      @include mq($until: desktop) {
        flex-direction: column;
        padding: inherit;
      }
      .player-wrapper {
        max-width: 45%;
        video {
          max-width: 45%;
          position: absolute;
          right: 2em;
          top: -3em;
          @include mq($until: desktop) {
            max-width: 100%;
            position: inherit;
          }
        }
      }
    }
  }
}
