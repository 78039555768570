@import '../../../../style/theme.scss';
@import '../../../../style/constants.scss';
@import '../../../../../node_modules/sass-mq/mq';
@import '../../../../style/mixins.scss';

.embedded-image {
  width: 100%;
  height: 10em;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 0.2em;
  @include mq($until: desktop) {
    height: 5em;
    background-size: contain;
  }
}
.icon {
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  border-radius: 50%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: -5em;
  width: 10em;
  height: 10em;
}

.modal-content {
  .adornment {
    background-color: #fff;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0;
  }
}
.content {
  &.embedded-content {
    width: $desktop-max-width;
    .bool-title {
      text-transform: uppercase;
      box-sizing: border-box;
      text-align: left;
      padding-left: 0.8em;
      padding-top: 1em;
      height: 2.1em;
      margin-left: 1.4em;
      max-width: 40%;
      font: {
        family: 'MontserratBold';
        size: 2em;
      }
      @include mq($until: desktop) {
        margin-left: 1em;
        padding-top: 2em;
        font-size: 1.2em;
        height: 3.1em;
        max-width: inherit;
        font: {
          family: 'MontserratBold';
          size: 2em;
        }
      }
    }
    .rich-text {
      font-size: 1.8em;
      padding-left: 2em;
      padding-right: 2em;
      width: 100%;
      display: flex;
      margin-top: 0.8em;
      justify-content: space-between;
      @include mq($until: desktop) {
        flex-direction: column;
        margin-top: 1em;
      }

      position: relative;
      .rich-text-content {
        width: 100%;
        padding-right: 2em;
        padding-left: 2em;
        padding-bottom: 1em;
        box-sizing: border-box;
        @include mq($until: desktop) {
          padding-right: 0.5em;
          padding-left: 0.5em;
          padding-bottom: 1em;
        }
        &:nth-child(2) {
          border-left-style: solid;
          border-left-width: 0.15em;
          @include mq($until: desktop) {
            border: none;
          }
        }
      }

      @include mq($until: desktop) {
        min-height: inherit;
        width: 100%;
        padding-left: inherit;
        padding: 1em 1em 0 1em;
      }
    }
    @include mq($until: desktop) {
      width: 100%;
      flex-direction: column;
      .rich-text {
        min-height: inherit;
        width: 100%;
        height: 100%;
      }
    }
  }
}
