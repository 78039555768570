@import '../../../../style/theme.scss';
@import '../../../../style/constants.scss';
@import '../../../../../node_modules/sass-mq/mq';
@import '../../../../style/mixins.scss';

.access-container {
  width: 500px;
  min-height: 300px;
  @include mq($until: desktop) {
    width: 100%;
  }
  p {
    margin-top: 2em;
    margin-bottom: 2em;
    padding: 1em;
    text-align: center;
    color: #44285d;
    font: {
      size: 1.1em;
      weight: 800;
    }
  }
}

.login-content {
  color: #44285d;
  font: {
    size: 1em;
    weight: 800;
  }
  p {
    padding-left: 1em;
    font: {
      size: 1em;
      weight: 800;
    }
  }
  .signup-container {
    padding: 1em;
    width: 940px;
    height: 490px;
    @include mq($until: desktop) {
      width: 100%;
      height: 100%;
    }

    form {
      .form-required-text {
        color: #dc5312;
        font-style: italic;
        font-size: 0.9em;
        margin-bottom: 0.5em;
      }
      fieldset {
        position: relative;
        margin-bottom: 1em;
        &:first-of-type {
          margin-top: 1em;
        }
      }
      label {
        .required {
          color: #dc5312;
          padding: 5px;
        }
        display: inline-block;
        min-width: 6em;
        &.large {
          width: 15em;
          min-width: inherit;
        }
        &.disclamer {
          margin-left: 3em;
        }
        @include mq($until: desktop) {
          min-width: 7em;
          &.large {
            min-width: inherit;
            width: 7em;
          }
        }
      }
      input,
      select {
        padding: 10px 20px;
        border: none;
        color: #333;
        background-color: #f1f1f1;
        font: {
          size: 0.9em;
          weight: 700;
        }
        @include mq($until: desktop) {
          width: 11em;
        }
      }
      .select-container {
        display: inline-block;
        position: relative;
        select {
          width: 16.2em;
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          &::-ms-expand {
            display: none;
          }
          @include mq($until: desktop) {
            width: 13.7em;
          }
        }
        .select_arrow {
          position: absolute;
          top: 16px;
          right: 15px;
          pointer-events: none;
          border-style: solid;
          border-width: 8px 5px 0px 5px;
          border-color: #44285d transparent transparent transparent;
        }
      }
      input[name='condition'] {
        position: absolute;
        left: -9999px;
      }
      .disclamer::before,
      .disclamer::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
      }
      input[type='checkbox'] + .disclamer::before,
      input[type='checkbox'] + .disclamer::after {
        border-radius: 50%;
      }
      .disclamer::before {
        display: block;
        cursor: pointer;
        width: 16px;
        height: 16px;
        border: 2px solid #44285d;
      }
      .disclamer::after {
        display: none;
        cursor: pointer;
        width: 17.5px;
        height: 17.5px;
        margin: 1px;
        background-color: #44285d;
      }
      input:focus + .disclamer::before {
        border-color: #44285d;
      }
      input:checked + .disclamer::after {
        display: block;
      }
      button {
        background-color: #fff;
        color: #44285d;
        display: block;
        border: 4px solid #44285d;
        border-radius: 30px;
        text-transform: uppercase;
        margin-left: auto;
        margin-right: auto;
        padding: map-get($map: $button, $key: padding-top)
          map-get($map: $button, $key: padding-left);
        font: {
          size: map-get($map: $button, $key: font-size);
          weight: 700;
        }
        &:hover {
          color: #fff;
          background-color: #44285d;
        }
        @include mq($until: desktop) {
          margin-top: 2em;
        }
      }
    }
  }
}
